import React from "react";
import "./Landing.css";
import { Typography} from "@mui/material";


const Card = () => {


  return (
    <div className="background-container">
      
      <Typography className="centered-text" fontFamily={"Rubik"} variant="h2" textAlign={"center"} color={"#FFFFFF"} paddingTop={5} paddingBottom={5}>Woods Custom Construction Solutions</Typography>

        
    </div>
  );
};

export default Card;
