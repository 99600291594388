import React from "react";

import "./profile.css";


const Profile = () => {

  return (
<section  className="profile-container">
        <div class="relative items-center w-full px-5 pt-24 mx-auto md:px-12 lg:px-16 max-w-7xl lg:py-24">
          <div>
            <div class="max-w-2xl">
              <p class="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                Quality custom work combined with a low country overhead means a great price and an addition to your home that you will love <br/><br/>Serving Belleville and area within approximately 120 km
              </p>
            </div>
          </div>
          <div class="">
            <div class="max-w-xl py-12 mx-auto text-left lg:max-w-7xl">
              <div>
                <div class="grid grid-cols-2 gap-12 lg:grid-cols-2 lg:space-y-0">
                  <div>
                    <div>
                      <div class="flex items-center justify-center w-12 h-12 text-black bg-gray-100 rounded-xl">
                        ❖
                      </div>
                      <p class="mt-4 text-lg font-medium leading-6 text-black">
                        Kitchens
                      </p>
                    </div>
                    <div class="mt-4 text-base text-gray-500">
                    We can take your ideas and work with you to create a design that will be your “dream come true” kitchen
                    </div>
                  </div>
                 
                  <div>
                    <div>
                      <div class="flex items-center justify-center w-12 h-12 text-black bg-gray-100 rounded-xl">
                        ❖
                      </div>
                      <p class="mt-4 text-lg font-medium leading-6 text-black">
                      Additional Projects 
                      </p>
                    </div>
                    <div class="mt-4 text-base text-gray-500">
                    Let us build for you what you need to complete your home.  Bars, Closets, Garage Cabinets, And More
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
};

export default Profile;
