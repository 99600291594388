import Navbar from "./Navbar/Navbar";
import Card from "./Landing/Landing";
import Slideshow from "./Slideshow/Slideshow";
import Contact from "./Contact/Contact";
import Testimonial from "./Testimonials/Testimonials";
import Profile from "./Profile/Profile";

function App() {
  return (
    <div>
      <Navbar />
      <Card />
      <Profile/>
      <Slideshow />
      <Testimonial/>
      <Contact />
    </div>
  );
}

export default App;
