import * as React from "react";
import {useState, useEffect} from 'react'
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import slide1 from "../assets/slide1.jpg";
import slide2 from "../assets/slide2.jpg";
import slide3 from "../assets/slide3.jpg";
import slide4 from "../assets/slide4.jpg";
import slide5 from "../assets/slide5.jpg";
import slide6 from "../assets/slide6.jpg";
import slide7 from "../assets/slide7.jpg";
import slide9 from "../assets/slide9.jpg";
import slide10 from "../assets/slide10.jpg";
import slide13 from "../assets/slide13.jpg";
import slide14 from "../assets/slide14.jpg";
import slide15 from "../assets/slide15.jpg";
import slide16 from "../assets/slide16.jpg";

import SwipeableViews from "react-swipeable-views-react-18-fix"

//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "closet",
    imgPath: slide1,
  },
  {
    label: "kitchen one",
    imgPath: slide2,
  },
  {
    label: "kitchen two",
    imgPath: slide3,
  },
  {
    label: "kitchen three",
    imgPath: slide4,
  },
  {
    label: "kitchen four",
    imgPath: slide5,
  },
  {
    label: "kitchen five",
    imgPath: slide6,
  },
  {
    label: "kitchen six",
    imgPath: slide7,
  },
  {
    label: "kitchen seven",
    imgPath: slide9,
  },
  {
    label: "kitchen eight",
    imgPath: slide10,
  },
  // {
  //   label: "kitchen eight",
  //   imgPath: slide11,
  // },
  // {
  //   label: "kitchen eight",
  //   imgPath: slide12,
  // },
  {
    label: "kitchen eight",
    imgPath: slide13,
  },
  {
    label: "kitchen eight",
    imgPath: slide14,
  },
  {
    label: "kitchen eight",
    imgPath: slide15,
  },
  {
    label: "kitchen eight",
    imgPath: slide16,
  },
];

export default function Slideshow() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const [width, setWidth] = useState ( window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <Typography
        fontFamily={"Rubik"}
        variant="h3"
        textAlign={"center"}
        color={"#770D06"}
       
      >
        Past Projects
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        <Box  id="projects-section" sx={{ maxWidth: 800, flexGrow: 1 }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 50,
              pl: 2,
              bgcolor: "background.default",
            }}
          ></Paper>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      height: isMobile ?300: 500,
                      display: "block",
                      maxWidth: 800,
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step.imgPath}
                    alt={step.label}
                  />
                ) : null}
              </div>
            ))}
          </SwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
      </Box>
    </>
  );
}
