import React from "react";
import { Typography} from "@mui/material";
import "./test.css";
function Testimonial(props) {
  return (
    <>
      <section class="py-20 bg-gray-100 sm:py-32"  id="testimonials-section">
      <Typography fontFamily={"Rubik"} variant="h3" textAlign={"center"} color={"#770D06"} paddingBottom={10}>Testimonials</Typography>

        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <ul
            
            class="grid max-w-2xl grid-cols-1 gap-6 mx-auto sm:gap-8 lg:max-w-none lg:grid-cols-3"
          >
            <li>
              <ul  class="flex flex-col gap-y-6 sm:gap-y-8">
                <li>
                  <figure class="relative h-full p-6 bg-white rounded-3xl">
                    <blockquote class="relative">
                      <p class="text-base text-gray-500">
                      I am so grateful to have been recommended to Jim Woods for our cabinetry in our new home. I absolutely loved the cabinets Jim built and installed in our kitchen and bar that I asked him to return to do our laundry room cabinets and built-in drawers in the master bedroom. The quality of Jim's work is exceptional, but he also made the whole process very enjoyable! He will be my first call for any future projects and I will continue to strongly recommend him every time I get the chance.
                      </p>
                    </blockquote>
                    <figcaption class="relative flex items-center justify-between pt-6 mt-6 border-t border-gray-100">
                      <div>
                        <div class="text-base text-black">Angela S.</div>
                        <div class="mt-1 text-sm text-gray-500">
                        Brighton, ON
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </li>
            <li>
              <ul  class="flex flex-col gap-y-6 sm:gap-y-8">
                <li>
                  <figure class="relative h-full p-6 bg-white rounded-3xl">
                    <blockquote class="relative">
                      <p class="text-base text-gray-500">
                      Jim has now built 2 kitchens for us.  I can sum everything up in one word – GREAT!!

                      </p>
                    </blockquote>
                    <figcaption class="relative flex items-center justify-between pt-6 mt-6 border-t border-gray-100">
                      <div>
                        <div class="text-base text-black">Elvis and Edith C.</div>
                        <div class="mt-1 text-sm text-gray-500">
                        Napanee, ON
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure class="relative h-full p-6 bg-white rounded-3xl">
                    <blockquote class="relative">
                      <p class="text-base text-gray-500">
                      Jim is the person I call when I need work done.  He has now created 2 kitchens as well as several other rooms in 2 houses for us.
                      </p>
                    </blockquote>
                    <figcaption class="relative flex items-center justify-between pt-6 mt-6 border-t border-gray-100">
                      <div>
                        <div class="text-base text-black">Suzan P.</div>
                        <div class="mt-1 text-sm text-gray-500">
                          Madoc, ON
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </li>
            <li>
              <ul  class="flex flex-col gap-y-6 sm:gap-y-8">
                <li>
                  <figure class="relative h-full p-6 bg-white rounded-3xl">
                    <blockquote class="relative">
                      <p class="text-base text-gray-500">
                      Jim built my first kitchen 25 years ago.  He has recently completed the kitchen in our new home.  In addition to the kitchen he did the walk-in pantry, laundry room and bathroom vanities.  He will be doing our closets when we are ready.  Kept to the budget and the building schedule.  His workmanship is excellent and everything was impeccably clean throughout the build.  We are extremely happy with the work Jim has done.
                      </p>
                    </blockquote>
                    <figcaption class="relative flex items-center justify-between pt-6 mt-6 border-t border-gray-100">
                      <div>
                        <div class="text-base text-black">                        Peggy and Rob D.
</div>
                        <div class="mt-1 text-sm text-gray-500">
                        Plainfield, ON
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </li>
                
              </ul>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
