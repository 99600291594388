import React from "react";
import "./Contact.css";
import { Typography} from "@mui/material";


const Contact = () => {
  return (
    <div className="c-container" id="c-container">
      <div className="content">
      <Typography fontFamily={"Rubik"} variant="h3" textAlign={"center"} color={"#FFFFFF"} paddingTop={5} paddingBottom={5}>Contact</Typography>

        <p>
          Give us a call or shoot us an email for a quote or consultation
          (weekend and evenings)! <br /> Start planning your dream kitchen
          today! 
        </p>
        <div className="contact-container">
        <div className="contact-content">
        
        <a href="tel:613-477-2470">(613) 477-2470</a>
        </div>
        <div className="contact-content">
        <a href="mailto:djwoods850@gmail.com">djwoods850@gmail.com </a>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
